/* eslint-disable @typescript-eslint/no-explicit-any */

// eslint-disable-next-line no-console
const dumy = () => console.info

const getFbp = () =>
  typeof window !== 'undefined' ? ((window as any).fbq ?? dumy) : dumy

export const pageview = () => {
  const fPixel = getFbp()
  fPixel('track', 'PageView')
}

type IFacebookEvents =
  | 'AddPaymentInfo'
  | 'AddToCart'
  | 'AddToWishlist'
  | 'CompleteRegistration'
  | 'Contact'
  | 'CustomizeProduct'
  | 'Donate'
  | 'FindLocation'
  | 'InitiateCheckout'
  | 'Lead'
  | 'PageView'
  | 'Purchase'
  | 'Schedule'
  | 'Search'
  | 'StartTrial'
  | 'SubmitApplication'
  | 'Subscribe'
  | 'ViewContent'

export const event = (name: IFacebookEvents, options = {}) => {
  const fPixel = getFbp()
  fPixel('track', name, options)
}

const fbp = { pageview, event }

export default fbp
