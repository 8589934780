/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRouter } from 'next/router'
import Script from 'next/script'
import { parseCookies, setCookie } from 'nookies'
import React, { useEffect } from 'react'
import ga from 'src/utils/pixels/gtag'
import config from '../../src/config'
import {
  ClarityScript,
  ConsoleMessage,
  FacebookScript,
  GtagScript,
  LinkedInScript1,
  LinkedInScript2
} from '../../src/constants/scripts'
import fbp from '../utils/pixels/fbp'

function Scripts() {
  const router = useRouter()

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(ConsoleMessage)
  }, [])

  useEffect(() => {
    fbp.pageview()

    const handleRouteChange = (url: URL) => {
      ga.pageview(url)
      fbp.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    const cookies = parseCookies()
    const sessionId = cookies.session_id
    const maxAge = 60 * 60 * 24 * 30
    if (!sessionId) {
      setCookie(null, 'session_id', Date.now().toString(), {
        maxAge,
        path: '/'
      })
    } else {
      setCookie(null, 'session_id', sessionId, { maxAge, path: '/' })
    }
  }, [])

  if (!config.isProd) return null

  return (
    <>
      <Script
        id="gtag"
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${config.pixels.ga}`}
      />
      <Script
        id="gtag-script"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: GtagScript }}
      />
      <Script
        id="microsoft-clarity"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: ClarityScript }}
      />
      <Script
        id="fb-pixel"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: FacebookScript }}
      />
      <Script
        id="hs-script-loader"
        strategy="lazyOnload"
        src="//js.hs-scripts.com/47956675.js"
      />

      <Script
        id="linkedin-insight-1"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: LinkedInScript1 }}
      />
      <Script
        id="linkedin-insight-2"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{ __html: LinkedInScript2 }}
      />

      <noscript>
        <img
          className="hidden size-px"
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=6688970&fmt=gif"
        />
      </noscript>
    </>
  )
}

export default React.memo(Scripts)
